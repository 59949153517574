/**
 * Cookie setter
 * @param {string} name - Name of cookie
 * @param {string} value - Value of cookie
 * @param {string} days - Amount of days cookie will be stored
 */

function setCookie(name, value, days){
    var expires;
    if (days) {
        var date = new Date();
        date.setTime(date.getTime()+(days*24*60*60*1000));
        expires = "; expires="+date.toGMTString();
    }
    else {
        expires = "";
    }
    document.cookie = name+"="+value+expires+"; path=/";
}

/**
 * Cookie getter
 * @param {string} name - Name of cookie
 */

function getCookie(name){
    var nameEQ = name + "=";
    var ca = document.cookie.split(';');
    for(var i=0;i < ca.length;i++) {
        var c = ca[i];
        while (c.charAt(0) === ' ') {
            c = c.substring(1,c.length);
        }
        if (c.indexOf(nameEQ) === 0) {
            return c.substring(nameEQ.length,c.length);
        }
    }
    return null;
}

/**
 * Cookie alert function
 * @param {integer} [action=0] - 0 for init, 1 for hide alert and set cookie.
 * @param {string} [message=] - Message of cookie alert.
 * @param {string} [closemsg=Close] - Closing button text.
 */

function cookieAlert(action,message,closemsg){

    action = typeof action !== 'undefined' ? action : 0;
    message = typeof message !== 'undefined' ? message : 'This site uses cookies to better meet your requirements. Proper configuration of browser options allows you to block or remove cookies but can also cause the site to not function properly or even at all. By continuing to use the site, with no change to your browser settings, you agree to the use of cookies.';
    closemsg = typeof closemsg !== 'undefined' ? closemsg : 'Close';

    if(action){
        setCookie('cookieAcceptance', 1, 365);
    }

    if(getCookie('cookieAcceptance')==1){
        $('body').removeClass('show-cookie-alert');
    } else {
        if(!($('#cookie-alert').length)){
            $('<div id="cookie-alert">'+message+'<a onclick="cookieAlert(1)">'+closemsg+'</a></div>').appendTo($('body'));
        }
        $('body').addClass('show-cookie-alert');
    }
}

/**
 * Function setting css class of device on body element
 */

function detectScreen(){
    var width=0;
    if (document.body && document.body.offsetWidth) {
        width = document.body.offsetWidth;
    }
    if (document.compatMode=='CSS1Compat' &&
        document.documentElement &&
        document.documentElement.offsetWidth ) {
        width = document.documentElement.offsetWidth;
    }
    if (window.innerWidth && window.innerHeight) {
        width = window.innerWidth;
    }

    var type='';

    if(Modernizr.touch){
        if(width<=680){
            type='phone'
        } else {
            type='tablet'
        }
    } else {
        if(width<=1366){
            type='notebook'
        } else {
            type='desktop'
        }
    }

    $('html').addClass(type);

}

$(document).ready(function(){
    cookieAlert();
    detectScreen();
});
