
$(window).load(function(){
    $('#overlay').css('opacity',0);

    setTimeout(function(){
        $('#overlay').css('display','none');
    },1100)

    $('a[href]').click(function(e) {
        e.preventDefault();
        newLocation = this.href;
        $('#overlay').css('display','block');

        setTimeout(function(){
            $('#overlay').css('opacity',1);

            setTimeout(function(){
                window.location = newLocation;
            },1100)
        },100)
    });
})

$(window).scroll(function(){
     console.log($(window).scrollTop());
    if($(window).scrollTop()>20){
        $('.modul.menu').addClass('mini');
    } else {
        $('.modul.menu').removeClass('mini');
    }
})

$('#hamburgerButton, nav a').click(function(){
    $('body').toggleClass('mobileMenu');
});
