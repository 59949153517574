function selectLang(id){
    jQuery.ajax({
        url: 'langs/change.php',
        type: 'POST',
        data: {
            id: id
        },
        dataType : 'json',
        success: function() {
            location.reload();
        },
        error: function() {

        }
    });
}
